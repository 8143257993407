import React, { useEffect } from "react"
import { useOutletContext, useParams } from "react-router";
import DataTableForm from "./DataTableForm";
import { authedApiFetch } from "../../utils/Api";

export async function action({ params, request }) {
  const formData = await request.formData();
  const form = Object.fromEntries(formData);

  const payload = {
    data_table: {
      catalog: form.catalog,
      name: form.name,
      alias: form.alias || null,
      client_accessible: form.client_accessible === "on",
      editable: form.editable === "on",
      unique_id_column: form.unique_id_column || null,
      approvable: form.approvable === "on",
      approved_at_column: form.approved_at_column || null,
      approved_by_column: form.approved_by_column || null,
      display_document: form.display_document === "on",
      document_location_column: form.document_location_column || null
    }
  }

  const response = await authedApiFetch({
    endpoint: `/data_tables/${params.dataTableId}`,
    method: "PATCH",
    payload: payload,
    returnResponse: true
  })

  const responseBody = await response.json();

  if (response.ok) {
    // return nothing, update went OK
    return null;
  } else {
    // provide error information back to form
    return responseBody
  }
}

const DataTableSettings = () => {
  // set value in parent through callback in outlet context
  const { dataTable, setTab } = useOutletContext();

  useEffect(() => {
    setTab('settings');
  }, []);

  const { tenantId } = useParams();

  return (
    <DataTableForm
      key={dataTable.id} // forces form to re-render when table changes
      tenantId={tenantId}
      existingValues={dataTable}
      confirmText="Save Changes"
    />
  )
}

export default DataTableSettings