import { Navigate, useOutletContext } from "react-router";

const DataTableIndex = () => {
  const { user } = useOutletContext();

  // Altvia users default to settings as index, clients default to records
  if (user.tenant_id) {
    return <Navigate to={`records`}/>;
  } else {
    return <Navigate to={`settings`}/>;
  }
}

export default DataTableIndex;