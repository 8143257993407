import { Box } from "@mui/material";
import { Navigate, useOutletContext } from "react-router";

export default function ConnectionIndex() {
  // get connections from parent outlet
  const { connections } = useOutletContext();

  // if connections exist, auto-navigate to first, otherwise show index
  if (connections.length > 0) {
    return (
      <Navigate to={`${connections[0].id}`}/>
    )
  } else {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height='100%'
        sx={{ flexGrow: 1 }}
      >
        This tenant has no connections. Click "Create New Connection" to see options.
      </Box>
    )
  }
}