import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Autocomplete,
  Chip,
  Stack,
  FormControlLabel,
  Switch,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem, 
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
import { Form, useRevalidator } from 'react-router';
import { authedApiFetch } from '../../utils/Api';

export const settingsFields = ({readOnly, settings, onChange, formData, operation = 'update'}) => {
  const handleArrayChange = ({value, name}) => {
    // mimic TextField event
    onChange({ target: { name: name, value: value } })
  }
  const handleSwitchChange = (event) => {
    // return checked attribute of target as value
    onChange({ target: { name: event.target.name, value: event.target.checked } })
  }

  const fieldDisabled = (field) => {
    return readOnly || field.readOnly || (operation == 'update' && field.updatable == false);
  }

  return settings.map((field, index) => {
    if (field.type == 'string[]') {
      const values = formData[field.name] || [];
      return (
        <div key={index}>
          <Autocomplete
            disabled={fieldDisabled(field)}
            multiple
            options={[]}
            freeSolo
            value={values}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            onChange={(_event, value, _reason) => handleArrayChange({value, name: field.name})}
            renderInput={(params) => (
              <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Custom Objects"
              placeholder="Type values and press Enter to add to list"
              />
            )}
          />
          {values.map((value) => {
            return <input type="hidden" name={`${field.name}[]`} value={value}/>
          })}
        </div>
      )
    } else if (field.type == 'boolean') {
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name={field.name}
                checked={formData[field.name]}
                onChange={handleSwitchChange}
              />
            }
            label={field.label}
            disabled={fieldDisabled(field)}
          />
        </FormGroup>
      )
    } else if (field.type == 'enum') {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
          <Select
            labelId={`${field.name}-label`}
            name={field.name}
            value={formData[field.name]}
            label={field.label}
            onChange={onChange}
            disabled={fieldDisabled(field)}
          >
            {
              field.options.map((option)=> {
                return <MenuItem key={option.name} value={option.name}>{option.label}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      )
    } else {
      return (
        <TextField
          disabled={fieldDisabled(field)}
          key={index}
          label={field.label}
          type={field.type}
          name={field.name}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          value={formData[field.name]}
        />
      )
    }
  })
}

const Settings = ({ service, connection, user }) => {
  const [formChanged, setFormChanged] = useState(false);
  const [formData, setFormData] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [dialogState, setDialogState] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState();

  const revalidator = useRevalidator();

  useEffect(() => {
    const fields = service.settings_fields || [];
    const initialFormData = {};

    fields.forEach(field => {
      const existingValue = connection.settings && connection.settings[field.name]
      const defaultValue = field.type == 'string[]' ? [] : ''
      initialFormData[field.name] = existingValue || defaultValue;
    });

    setFormData(initialFormData);
    setFormChanged(false);
  }, [service, connection]);

  useEffect(() => {
    const connectionCreating = connection.status == 'creating';
    const productionEnvironment = connection.environment == 'production';
  
    setReadOnly(connectionCreating || productionEnvironment);
  }, [connection])

  const handleCloseDialogue = (actionName) => {
    let dialogStateClone = {...dialogState};
    dialogStateClone[actionName] = false;
    setDialogState(dialogStateClone);
  }

  const handleOpenDialogue = (actionName) => {
    let dialogStateClone = {...dialogState};
    dialogStateClone[actionName] = true;
    setDialogState(dialogStateClone);
  }

  const handleAction = async (action) => {
    handleCloseDialogue(action.name);

    try {
      await authedApiFetch({
        endpoint: `/connections/${connection.id}/${action.endpoint}`,
        method: 'POST'
      });

    } catch (e) {
      setSnackbarContent({ message: e.message, severity: 'error' });
      setSnackbarOpen(true);
    }
    setSnackbarContent({ message: 'Action successful', severity: 'success' });
    setSnackbarOpen(true);

    // imperatively revalidate b/c not using React Router action.
    // TODO: refactor so that this goes through a clientAction.
    revalidator.revalidate();
  }

  return (
    <Box>
      <Form id="settings-form" method="post" action="update">
        {settingsFields({
          readOnly: readOnly || user.role == 'altvia_user',
          settings: [...service.settings_fields], // spreading makes reactive
          formData: {...formData}, // spreading makes function call reactive
          onChange: ((e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setFormChanged(true);
          })
        })}
      </Form>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button
          form="settings-form"
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          disabled={user.role == 'altvia_user' || readOnly || !formChanged}
        >
          Save Settings
        </Button>
      </Stack>
      {/* Display actions according to service conditions. TODO: move to function */}
      {service.actions?.length > 0 &&
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          {service.actions.map((action) => {
            const conditions = action.conditions;
            let disable = false;
            let tooltip;
            let hide = false;

            // return early if user's role can't access action
            if (action.rolesWithAccess && !action.rolesWithAccess.includes(user.role)) {
              return;
            }

            conditions?.forEach((condition) => {
              // if condition specifies attribute, look for it on field
              const actualValue = condition.attribute ?
                connection[condition.field]?.[condition.attribute] :
                connection[condition.field]

              if (actualValue != condition.value) {
                if (condition.whenNotMet == 'disable') {
                  disable = true;
                  tooltip = condition.tooltip;
                } else {
                  hide = true;
                }
              }
            })

            if (hide) {
              return;
            } else if (disable) {
              return (
                <Tooltip title={tooltip}>
                  {/* span required when tooltip element is disabled per MUI */}
                  <span>
                    <Button variant="contained" disabled>
                      {action.label}
                    </Button>
                  </span>
                </Tooltip>
              )
            } else {
              return (
                <>
                <Button variant="contained" disabled={connection.status == 'creating'} onClick={() => handleOpenDialogue(action.name)}>
                  {action.label}
                </Button>
                <Dialog
                  open={dialogState[action.name] || false}
                  onClose={handleCloseDialogue}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Confirm Action: {action.label}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {action.confirmText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseDialogue(action.name)}>Cancel</Button>
                    <Button onClick={() => handleAction(action)} autoFocus>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
                </>
              )              
            }
          })}
        </Stack>
      }
      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={()=> setSnackbarOpen(false)}
          severity={snackbarContent?.severity}
          variant="filled"
        >
          {snackbarContent?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
