import { Box } from "@mui/material";
import { Navigate, useOutletContext } from "react-router";

export default function RootIndex() {
  const { tenants } = useOutletContext();

  if (tenants.length > 0) {
    return <Navigate to={`tenants/${tenants[0].id}/dashboard`}/>;
  } else {
    return (
    <Box sx={{ flexGrow: 1, pt: 0, pb: 3, pl: 3, pr: 3, height: '100%' }}>
      Your user does not have access to any tenants. Please reach out to an Altvia administrator to gain access to your tenant.
    </Box>
    )
  }
}