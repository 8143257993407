import React, { useState, Suspense } from 'react';
import {
  Typography,
  Box,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  CircularProgress} from '@mui/material';
import { authedApiFetch } from '../../utils/Api';
import { MoreVert } from '@mui/icons-material';
import { Await, useRevalidator } from 'react-router';

const JobStatus = ({jobDataPromise, tenant, user}) => {
  const [pipelineMenuAnchor, setPipelineMenuAnchor] = useState();
  const revalidator = useRevalidator();

  // Handle pipeline menu interactions
  const handlePipelineMenuOpen = (event) => {
    setPipelineMenuAnchor(event.currentTarget);
  }
  const handlePipelineMenuClose = () => {
    setPipelineMenuAnchor(null);
  }
  const handleRunPipelineClick = async () => {
    handlePipelineMenuClose();

    // TODO: refactor as React Router action to take advantage of loading state
    await authedApiFetch({
      endpoint: `/tenants/${tenant.id}/data_job_runs`,
      method: 'POST'
    });

    // instruct React Router to re-invoke loader function for this page
    revalidator.revalidate();
  }
  const handleUpdateRepoClick = async () => {
    handlePipelineMenuClose();

    // TODO: refactor as React Router action to take advantage of loading state
    await authedApiFetch({
      endpoint: `/tenants/${tenant.id}/data_job_runs/update_repo`,
      method: 'POST'
    });

    // instruct React Router to re-invoke loader function for this page
    revalidator.revalidate();
  }

  const jobSection = (jobData) => {
    if (!jobData.job_found) {
      return (
        <>
          Job does not exist
        </>
      )
    } else if (jobData.job_runs.length == 0) {
      return (
        <>
          Job has not run yet
        </>
      )
    } else {
      const lastRun = jobData.job_runs[0];
      const lastRunDate = new Date(lastRun.start_time);
      const formatter = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      });
      const lastRunFormattedDate = formatter.format(lastRunDate);

      // if state is terminated, use termination code, otherwise use state
      const lastRunStatus = lastRun.status;
      const lastRunStatusMessage = lastRunStatus.state != 'TERMINATED' ?
        lastRunStatus.state :
        lastRunStatus.termination_details.code

      return (
        <>
          <div>
            Last run: {lastRunFormattedDate}
          </div>
          <div>
            Status: {lastRunStatusMessage}
          </div>
        </>
      )
    }
  };

  return (
    <Suspense fallback={
      <Paper
        variant="outlined"
        sx={{mt: 2, p: 2, rowGap: 1, display: "flex", flexDirection: "column" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h7" >Prod Job Status</Typography>
        </Box>
        <Divider/>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress/>
        </Box>
      </Paper>
    }>
      <Await resolve={jobDataPromise}>
        {(jobData) => (
          <Paper
            variant="outlined"
            sx={{mt: 2, p: 2, rowGap: 1, display: "flex", flexDirection: "column" }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h7" >Prod Job Status</Typography>
              {(['altvia_admin', 'altvia_power_user'].includes(user.role) && jobData.job_found) &&
                <>
                  <IconButton size='small' onClick={handlePipelineMenuOpen} sx={{p: 0}}>
                    <MoreVert/>
                  </IconButton>
                  <Menu
                    anchorEl={pipelineMenuAnchor}
                    open={Boolean(pipelineMenuAnchor)}
                    onClose={handlePipelineMenuClose}
                  >
                    <MenuItem onClick={handleRunPipelineClick}>
                      Run Job
                    </MenuItem>
                    <MenuItem onClick={handleUpdateRepoClick}>
                      Update Client Repo & Run Job
                    </MenuItem>
                  </Menu>
                </>
              }
            </Box>
            <Divider/>
            {jobSection(jobData)}
          </Paper>

        )}
      </Await>
    </Suspense>
  )
}

export default JobStatus;