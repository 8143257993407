import React from 'react';
import ConnectionsSidebar from './ConnectionsSidebar';
import { authedApiFetch } from '../../utils/Api'
import { Outlet, useOutletContext, useLoaderData } from 'react-router';
import { Box } from '@mui/material';

export async function loader({ params }) {
  const [connections, services] = await Promise.all([
    authedApiFetch({
      endpoint: `/tenants/${params.tenantId}/connections`,
      method: 'GET'
    }),
    authedApiFetch({
      endpoint: `/services`,
      method: 'GET'
    })
  ]);

  // not awaited, loaded later by component using suspense API
  const jobDataPromise = authedApiFetch({
    endpoint: `/tenants/${params.tenantId}/data_job_runs`,
    method: 'GET'
  })

  return { connections, services, jobDataPromise };
}

export async function action({ request, params }) {
  const requestJson = await request.json();
  const { name, serviceId, environment, ...settings} = requestJson;

  const connection = await authedApiFetch({
    method: 'POST',
    endpoint: `/tenants/${params.tenantId}/connections`,
    payload: {
      name: name,
      settings: settings,
      environment: environment,
      service_id: serviceId
    }
  });

  return connection;
}

const Connections = () => {
  const { tenant, user } = useOutletContext();
  const { connections, services, jobDataPromise } = useLoaderData();

  return (
    <div style={{height: 'calc(100vh - 122px)', boxSizing: 'border-box', display: 'flex' }}>
      <ConnectionsSidebar
        connections={connections}
        services={services}
        jobDataPromise={jobDataPromise}
        tenant={tenant}
        user={user}
      />
      <Box
        component="main"
        boxSizing='border-box'
        height="100%"
        width="calc(100vw - 300px)"
        sx={{ p: 3, overflowY: 'auto' }}
        display='flex'
        flexDirection='column'
        rowGap="16px"
      >
        <Outlet
          context={{ connections, services, user }}
        />
      </Box>
    </div>
  );
}

export default Connections;