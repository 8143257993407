import React, { useState } from 'react';
import { authedApiFetch } from '../../utils/Api';
import { Outlet, useLoaderData, useOutletContext, useParams } from 'react-router';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Link } from 'react-router';

export async function loader({ params }) {
  const dataTable = await authedApiFetch({
    endpoint: `/data_tables/${params.dataTableId}`,
    method: 'GET'
  })

  return { dataTable };
}

const DataTable = () => {
  const { dataTable } = useLoaderData();
  const { user } = useOutletContext();

  // setting tab index based on page's index in array, one for Altvia users, one for not
  const pages = user.tenant_id ? ['records'] : ['settings', 'records'];
  const [currentTab, setCurrentTab] = useState(0)
  const handleSetTab = (currentTab) => {
    setCurrentTab(pages.indexOf(currentTab));
  }

  // for setting absolute routes to sub-pages
  const { tenantId, dataTableId } = useParams();

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" sx={{ p: 1 }}>
          Data Table: {dataTable.alias || dataTable.name}
        </Typography>
        <Tabs value={currentTab}>
          {!user.tenant_id &&
            <Tab
              label="Settings"
              to={`/tenants/${tenantId}/data-tables/${dataTableId}/settings`}
              component={Link}
            />
          }
          <Tab
            label="Records"
            to={`/tenants/${tenantId}/data-tables/${dataTableId}/records`}
            component={Link}
          />
        </Tabs>
      </Box>
      <Outlet context={{user, dataTable, setTab: handleSetTab }} />
    </>
  )
}

export default DataTable;