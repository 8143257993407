import { Box, Paper } from '@mui/material';
import React from 'react';

const SubSidebar = ({ children }) => {

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        minWidth: 300,
        boxSizing: 'border-box',
        flexGrow: 1
      }}
    >
      <Box sx={{ overflowY: 'auto', overflowX: 'hidden', flex: '1 1 auto', p: 2 }}>
        {children}
      </Box>
    </Paper>
  )
}

export default SubSidebar;