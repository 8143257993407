import { Autocomplete, Button, FormControlLabel, Paper, Switch, TextField, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, useActionData } from "react-router";
import { authedApiFetch } from "../../utils/Api";

const DataTableForm = ({ tenantId, confirmText, existingValues = {} }) => {
  const [catalogOptions, setCatalogOptions] = useState(null);
  const [tableOptions, setTableOptions] = useState([]);
  const [error, setError] = useState(null);

  // managed state so we can later make sections collapsed based on switches
  const [formData, setFormData] = useState(existingValues);

  // get any data returned from submit action, which would be validation errors
  const fieldErrors = useActionData();

  // retrieve list of catalogs
  useEffect(() => {
    if (catalogOptions?.length > 0) {
      return;
    }

    (async () => {
      const catalogs = await authedApiFetch({
        endpoint: `/catalogs`,
        method: 'GET'
      });
      setCatalogOptions(catalogs);
    })();
  }, []);

  // retrieve list of tables in selected catalog
  useEffect(() => {
    if (!formData.catalog || !tenantId) {
      return;
    }

    (async () => {
      const tablesResp = await authedApiFetch({
        endpoint: `/tenants/${tenantId}/data_tables/options?catalog=${formData.catalog}`,
        method: 'GET'
      });

      if (tablesResp.success) {
        const tableOptions = formData.name ? [...new Set([...tablesResp.tables, formData.name])] : tablesResp.tables;
        setError(null);
        setTableOptions(tableOptions);
      } else {
        setError(tablesResp.message);
        setTableOptions([]);
      }
    })();
  }, [tenantId, formData.catalog]);

  const handleChange = (event) => {
    const newFormData = {...formData};
    newFormData[event.target.name] = event.target.value
    setFormData(newFormData);
  }

  const handleSwitchChange = (event) => {
    const newFormData = {...formData};
    newFormData[event.target.name] = event.target.checked
    setFormData(newFormData);
  }

  const handleGenericChange = (name, newValue) => {
    const newFormData = {...formData};
    newFormData[name] = newValue
    setFormData(newFormData);
  }

  return (
    <Form method="post">
      <Paper variant="outlined" display="flex" flexdirection="column" sx={{ p: 3 }}>
        <Autocomplete
          options={catalogOptions || []}
          value={formData.catalog}
          onChange={(_event, newValue) => handleGenericChange("catalog", newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={fieldErrors?.name}
              label="Catalog"
              margin="normal"
            />
          )}
        />
        <input type="hidden" name="catalog" value={formData.catalog}/>
        { error && <Alert severity="error">{error}</Alert> }
        {
          formData.catalog && tableOptions.length > 0 &&
          <>
            <Autocomplete
              options={tableOptions || []}
              value={formData.name}
              onChange={(_event, newValue) => handleGenericChange("name", newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={fieldErrors?.name}
                  helperText={fieldErrors?.name}
                  label="Table Name"
                  margin="normal"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <input type="hidden" name="name" value={formData.name}/>
            <TextField
              label="Alias"
              type="string"
              name="alias"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formData.alias}
              onChange={handleChange}
              error={fieldErrors?.alias}
              helperText={fieldErrors?.alias}
            />
            <FormControlLabel
              control={
                <Switch
                  name="client_accessible"
                  checked={formData.client_accessible}
                  onChange={handleSwitchChange}
                />
              }
              label="Client Accessible"
            />
            <FormControlLabel
              control={<Switch name="editable" onChange={handleSwitchChange} checked={formData.editable} />}
              label="Editable"
            />
            <TextField
              label="Unique ID Column"
              type="string"
              name="unique_id_column"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formData.unique_id_column}
              onChange={handleChange}
              error={fieldErrors?.unique_id_column}
              helperText={fieldErrors?.unique_id_column}
            />
            <FormControlLabel
              control={<Switch name="approvable" onChange={handleSwitchChange} checked={formData.approvable} />}
              label="Approvable"
            />
            <TextField
              label="Approved At Column"
              type="string"
              name="approved_at_column"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formData.approved_at_column}
              onChange={handleChange}
              error={fieldErrors?.approved_at_column}
              helperText={fieldErrors?.approved_at_column}
            />
            <TextField
              label="Approved By Column"
              type="string"
              name="approved_by_column"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formData.approved_by_column}
              onChange={handleChange}
              error={fieldErrors?.approved_by_column}
              helperText={fieldErrors?.approved_by_column}
            />
            <FormControlLabel
              control={<Switch name="display_document" onChange={handleSwitchChange} checked={formData.display_document} />}
              label="Display Document"
            />
            <TextField
              label="Document Location Column"
              type="string"
              name="document_location_column"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formData.document_location_column}
              onChange={handleChange}
              error={fieldErrors?.document_location_column}
              helperText={fieldErrors?.document_location_column}
            />

            <Button
              type="submit"
              variant="contained"
            >
              {confirmText}
            </Button>
          </>
        }
      </Paper>
    </Form>
  )
}

export default DataTableForm;