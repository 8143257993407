import { Outlet, useLoaderData, useOutletContext } from "react-router"
import Sidebar from "./Sidebar";
import { Box } from "@mui/material";
import { authedApiFetch } from "../utils/Api";

export const tenantLoader = async ({ params }) => {
  try {
    // Get tenant ID from URI
    const { tenantId } = params;

    // TODO: move page-specific calls to page loaders
    const [tenant, users, news] = await Promise.all([
      authedApiFetch({ endpoint: `/tenants/${tenantId}`, method: 'GET'}), // contains more data than index
      authedApiFetch({ endpoint: `/tenants/${tenantId}/users`, method: 'GET'})
    ]);

    return { tenant, users };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

const Tenant = () => {
  const data = useLoaderData();
  const outletData = useOutletContext();

  return (
    <>
      <Sidebar tenant={data.tenant} user={outletData.user} />
      <Box component='main' sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
        <Outlet context={{...data, ...outletData}}/>
      </Box>
    </>
  )
};

export default Tenant;