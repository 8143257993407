import { Box } from "@mui/material";
import { Navigate, useOutletContext } from "react-router";

export default function DataIndex() {
  const { tables, user } = useOutletContext();

  // if connections exist, auto-navigate to first, otherwise show index
  if (tables.length > 0) {
    return <Navigate to={`${tables[0].id}`}/>;
  } else {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height='100%'
        sx={{ flexGrow: 1 }}
      >
        This tenant does not have access configured to any tables. {!user.tenant_id && 'Click "Create Table Access" to see options.'}
      </Box>
    )
  }
}