import { Box, Typography } from "@mui/material";
import DataTableForm from "./DataTableForm"
import { useParams, redirect } from "react-router";
import { authedApiFetch } from "../../utils/Api";

export async function action({ params, request }) {
  const formData = await request.formData();
  const form = Object.fromEntries(formData);

  const payload = {
    data_table: {
      catalog: form.catalog,
      name: form.name,
      alias: form.alias || null,
      client_accessible: form.client_accessible === "on",
      editable: form.editable === "on",
      unique_id_column: form.unique_id_column || null,
      approvable: form.approvable === "on",
      approved_at_column: form.approved_at_column || null,
      approved_by_column: form.approved_by_column || null,
      display_document: form.display_document === "on",
      document_location_column: form.document_location_column || null
    }
  }

  const response = await authedApiFetch({
    endpoint: `/tenants/${params.tenantId}/data_tables`,
    method: "POST",
    payload: payload,
    returnResponse: true
  })

  const responseBody = await response.json();

  if (response.ok) {
    // navigate to show page
    return redirect(`../${responseBody.id}`);
  } else {
    // provide error information back to form
    return responseBody
  }
}

const DataTableNew = () => {
  const { tenantId } = useParams();

  return (
    <Box display="flex" flexDirection="column" rowGap="16px">
      <Typography variant="h5" sx={{ p: 1 }}>
        Create Table Access
      </Typography>
      <DataTableForm tenantId={tenantId} confirmText="Create"/>
    </Box>
  )
}

export default DataTableNew;