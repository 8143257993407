import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Card, CardActionArea, CardMedia, CardContent, Button, TextField, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import logoMapping from '../../assets/images/services-logos';
import { settingsFields } from './Settings';
import { useFetcher, useNavigate, useParams } from 'react-router';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const NewConnectionForm = ({ service, fetcher }) => {
  const initialFormData = { serviceId: service.id };
  if (service.single_connection != true) {
    initialFormData.name = ''
  }

  // if service is configured to use environments, new connections are in dev
  if (service.use_environments == true) {
    initialFormData.environment = 'development'
  }

  service.settings_fields.forEach(field => {
    if (field.type == 'string[]') {
      initialFormData[field.name] = [];
    } else if (field.type == 'enum' && field.default) {
      initialFormData[field.name] = field.default;
    } else {
      initialFormData[field.name] = '';
    }
  });

  const [formData, setFormData] = useState(initialFormData);
  const { tenantId } = useParams();
  
  const handleFieldChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  
  const handleSubmit = () => {
    // manually submit via JSON b/c FormData API is not good with array values
    fetcher.submit(formData, {
      method: "post",
      action: `/tenants/${tenantId}/connections`,
      encType: "application/json"
    });
  };

  return (
    <>
      {service.settings_instructions && service.settings_instructions.map((section)=> {
        const markup = { __html: JSON.parse(section.content) };
        return (
          <Accordion sx={{mb: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {section.label}
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={markup}/>
            </AccordionDetails>
          </Accordion>
        )
      })}
      <TextField
        disabled
        key='serviceName'
        label='Selected Connection Type'
        type='string'
        variant="outlined"
        margin="normal"
        fullWidth
        value={service.name}
      />
      {service.use_environments &&
        <TextField
          key='environment'
          label='Connection Environment'
          type='string'
          name='environment'
          variant="outlined"
          margin="normal"
          slotProps={{
            htmlInput: {style: {textTransform: 'capitalize'}}
          }}
          disabled
          fullWidth
          value={formData.environment}
        />
      }
      {service.single_connection != true && 
        <TextField
          key='connection_name'
          label='Connection Name'
          type='string'
          name='name'
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleFieldChange}
          value={formData.name}
        />
      }
      {settingsFields({
        settings: service.settings_fields,
        formData: formData,
        onChange: handleFieldChange,
        operation: 'create'
      })}
      <Button variant='contained' sx={{ mt: 2 }} onClick={handleSubmit}>
        Create Connection
      </Button>
    </>
  )
}

const NewConnectionModal = ({ open, handleClose, services, connections }) => {
  const [selectedService, setSelectedService] = useState();
  const [serviceOptions, setServiceOptions] = useState([]);

  const fetcher = useFetcher();
  const navigate = useNavigate();

  // navigate to new connection page after submission. This is a hack necessary
  // because `submit()` is being used so the data can be serialized as JSON.
  useEffect(() => {
    if (fetcher.data) {
      navigate(`${fetcher.data.id}`);
      handleModalClose();
    }
  },[fetcher.data])

  useEffect(() => {
    // display only services that allow multiple connections or have none
    const newServiceOptions = services.filter((s) => {
      const allowsMultiple = s.single_connection != true;
      const hasNone = connections.filter((c) => c.service_id == s.id).length == 0;
      return allowsMultiple || hasNone
    });
    setServiceOptions(newServiceOptions);
  }, [services, connections])

  const handleServiceClick = (service) => {
    setSelectedService(service);
  }

  const handleModalClose = () => {
    setSelectedService(null);
    handleClose();
  }

  return (
    <Modal open={open} onClose={handleModalClose} aria-labelledby="new-connection-modal-title" aria-describedby="new-connection-modal-description">
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" marginBottom={2}>Add New Connection</Typography>
        {selectedService ?  (
          <NewConnectionForm service={selectedService} fetcher={fetcher} />
        ) : (
        <Grid container spacing={2} justifyContent="center">
          {serviceOptions.map((service, index) => (
            <Grid
              key={index}
              size={{
                xs: 6,
                sm: 4,
                md: 3,
                lg: 2
              }}>
              <Card sx={{ maxWidth: 150 }} onClick={() => handleServiceClick(service)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{ width: 48, height: 48, margin: 'auto', padding: 1 }}
                    image={logoMapping[service.logo]} // Use logo from services
                    alt={`${service.name} Logo`}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="body2" component="div" textAlign="center">
                      {service.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default NewConnectionModal;
